import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import ContainerCard from "./Components/ContainerCard/ContainerCard";
import MasonryLayout from "./Components/MasonryLayout/MasonryLayout";
import Navbar from "../Navbar";
import Footer from "../Footer";
import styles from "./App.module.css";
import { FaXmark, FaDownload, FaHeart } from "react-icons/fa6";
import "./Gallery.css";
import "../../index.css";
import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { add_Back } from "../../address";
import perfil_default from "../../assets/perfil_default.jpg";
import { useTranslation } from "react-i18next";

// Importar  "Upscale"
import { geraPrompt as geraPrompt1024x1024 } from "../../apiComfy/Upscale/Upscale_1024x1024.js";
import { geraPrompt as geraPrompt1536x1536 } from "../../apiComfy/Upscale/Upscale_1536x1536.js";

import { geraPrompt as geraPrompt1024x1344 } from "../../apiComfy/Upscale/Upscale_1024x1344.js";
import { geraPrompt as geraPrompt1344x1024 } from "../../apiComfy/Upscale/Upscale_1344x1024.js";

import { geraPrompt as geraPrompt1024x1536 } from "../../apiComfy/Upscale/Upscale_1024x1536.js";
import { geraPrompt as geraPrompt1536x1024 } from "../../apiComfy/Upscale/Upscale_1536x1024.js";

import { geraPrompt as geraPrompt1224x816 } from "../../apiComfy/Upscale/Upscale_1224x816.js";
import { geraPrompt as geraPrompt816x1244 } from "../../apiComfy/Upscale/Upscale_816x1224.js";

import { geraPrompt as geraPrompt1224x1840 } from "../../apiComfy/Upscale/Upscale_1224x1840.js";
import { geraPrompt as geraPrompt1840x1224 } from "../../apiComfy/Upscale/Upscale_1840x1224.js";

import { geraPrompt as geraPrompt1336x1752 } from "../../apiComfy/Upscale/Upscale_1336x1752.js";
import { geraPrompt as geraPrompt1752x1336 } from "../../apiComfy/Upscale/Upscale_1752x1336.js";

// Importar  "Canvas"
import { geraPrompt as geraPromptCanvas1024x1024 } from "../../apiComfy/Upscale/Upscale_Canvas_1024x1024.js";
import { geraPrompt as geraPromptCanvas1536x1536 } from "../../apiComfy/Upscale/Upscale_Canvas_1536x1536.js";

import { geraPrompt as geraPromptCanvas1024x1344 } from "../../apiComfy/Upscale/Upscale_Canvas_1024x1344.js";
import { geraPrompt as geraPromptCanvas1344x1024 } from "../../apiComfy/Upscale/Upscale_Canvas_1344x1024.js";

import { geraPrompt as geraPromptCanvas1024x1536 } from "../../apiComfy/Upscale/Upscale_Canvas_1024x1536.js";
import { geraPrompt as geraPromptCanvas1536x1024 } from "../../apiComfy/Upscale/Upscale_Canvas_1536x1024.js";

import { geraPrompt as geraPromptCanvas1224x816 } from "../../apiComfy/Upscale/Upscale_Canvas_1224x816.js";
import { geraPrompt as geraPromptCanvas816x1224 } from "../../apiComfy/Upscale/Upscale_Canvas_816x1224.js";

import { geraPrompt as geraPromptCanvas1224x1840 } from "../../apiComfy/Upscale/Upscale_Canvas_1224x1840.js";
import { geraPrompt as geraPromptCanvas1840x1224 } from "../../apiComfy/Upscale/Upscale_Canvas_1840x1224.js";

import { geraPrompt as geraPromptCanvas1336x1752 } from "../../apiComfy/Upscale/Upscale_Canvas_1336x1752.js";
import { geraPrompt as geraPromptCanvas1752x1336 } from "../../apiComfy/Upscale/Upscale_Canvas_1752x1336.js";

const PersonalGallery = () => {
  // Função para verificar se o token está presente e válido
  function verificarToken() {
    const token = Cookies.get("token");
    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.init({ ns: "gallery" });
  }, [i18n]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [favoriteImages, setFavoriteImages] = useState({});
  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar o carregamento das imagens
  const [page, setPage] = useState(1);
  const [imagesPerPage] = useState(12);
  const [loadingMore, setLoadingMore] = useState(false); // Estado para controlar o carregamento adicional de imagens
  const [isHeartRed, setIsHeartRed] = useState(false);
  const [likes, setLikes] = useState(0);
  const [allImages, setAllImages] = useState([]);
  const [lastClickTime, setLastClickTime] = useState(0);
  const LIKE_INTERVAL = 500; // Intervalo em milissegundos
  // Estado para controlar a visibilidade do botão REFINE
  const [isUpscaleAllowed, setIsUpscaleAllowed] = useState(false);

  const upscaleModels = [
    "BrainstormGenUpscale",
    "CanvasAlchemyUpscale",
    "ReRenderUpscale",
    "DecorateUpscale",
    "Sketch2RenderUpscale",
  ];

  //-----------------------------------------------
  async function fetchGeraImagemComfy(options) {
    try {
      const response = await fetch(`${add_Back}/ia/createImage`, options);
      const data = await response.json();
      console.log("Data fetch gera imagemcomfy: ", data);
      if (data.image) {
        localStorage.setItem("moedas_total", data.moedas);
        console.log("Moedas totais: ", data.moedas);

        return { erro: 0, msg: data.image, moedas: data.moedas };
      }
      return { erro: 1, msg: data };
    } catch (error) {
      console.error("Erro:", error);
      return { erro: 1, msg: error };
    }
  }
  //-----------------------------------------------

  const openModal = (categoryImage) => {
    setSelectedImage(categoryImage);
    setIsModalOpen(true);
    nomePage(categoryImage); // Chama a função quando a imagem é clicada
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const handleHovering = () => {
    setIsHovering(!isHovering);
  };

  const handleHeartClick = async (event) => {
    event.stopPropagation();
    const currentTime = Date.now();

    if (currentTime - lastClickTime < LIKE_INTERVAL) {
      // Se os cliques forem rápidos, mantenha o like
      setIsHeartRed(true);
      setLastClickTime(currentTime);
      return;
    }

    // Alterna o estado do like e atualiza o banco de dados
    setIsHeartRed(!isHeartRed);
    setLastClickTime(currentTime);

    try {
      const newLikes = !isHeartRed ? likes + 1 : likes - 1;
      const response = await axios.put(
        `${add_Back}/galeria/${selectedImage.id}`,
        {
          qde_likes: newLikes,
        }
      );
      setLikes(response.data.qde_likes);
    } catch (error) {
      console.error("Erro ao atualizar o número de likes:", error);
    }
  };

  useEffect(() => {
    document.title = "Personal Gallery - Arcseed AI";
    return () => {
      document.title = "Arcseed AI";
    };
  }, []);

  const { t } = useTranslation();

  const handleDownloadClick = async (imageUrl) => {
    try {
      // Realiza uma requisição para obter o blob da imagem
      const response = await fetch(imageUrl.imagem, { mode: 'cors', cache: 'no-cache' });
      if (!response.ok) {
        throw new Error(`Erro ao buscar a im  agem: ${response.status} ${response.statusText}`);
      }

      // Converte a resposta para Blob
      const blob = await response.blob();

      // Cria um link temporário para download
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(blob);

      // Configura o link para download
      link.href = objectURL;
      link.download = `${imageUrl.nick_name}.jpg`;

      // Adiciona ao DOM, clica e remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Libera o objeto temporário da memória
      URL.revokeObjectURL(objectURL);

      console.log("Download realizado com sucesso.");
    } catch (error) {
      console.error("Erro ao baixar a imagem:", error);
      alert("Não foi possível baixar a imagem.");
    }
  };
  //------------------------------------------------------------------
  const nomePage = async (image) => {
    const token = Cookies.get("token");
    console.log("ID da imagem: ", image.id);
    try {
      const imageResponse = await axios.get(
        `${add_Back}/imagens/${image.id_imagem}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const id_modelo = imageResponse.data[0].id_modelo;
      console.log(`Nome da página: ${id_modelo}`);

      // Verifica se o id_modelo não está nos upscaleModels
      if (!upscaleModels.includes(id_modelo)) {
        setIsUpscaleAllowed(true);
      } else {
        setIsUpscaleAllowed(false);
      }
    } catch (error) {
      console.error("Erro ao puxar o modelo:", error);
    }
  };
  //------------------------------------------------------------------
  const upscaleGallery = async (image) => {
    const token = Cookies.get("token");
    const decodedToken = jwtDecode(token);
    const user = decodedToken.clienteId;

    const nomeImage = `${user}_${Date.now()}.jpg`;
    console.log("Nome da imagem:", nomeImage);

    function getBase64ImageDimensions(base64String) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
          const dimensions = {
            width: this.naturalWidth,
            height: this.naturalHeight,
          };
          resolve(dimensions);
        };

        img.onerror = function () {
          reject(new Error("Erro ao carregar a imagem Base64."));
          //setAlerta("upscaleError");
        };

        // Adiciona o prefixo se não estiver presente
        if (!base64String.startsWith("data:image")) {
          base64String = "data:image/jpeg;base64," + base64String;
        }

        img.src = base64String;
      });
    }

    //pega o base64 da imagem
    try {
      const imageURLimageResponse = await axios.get(
        `${add_Back}/galeria-imagem/${selectedImage.id}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const imageURL = imageURLimageResponse.data[0].imagem;
      console.log(`imagem selecionada: ${imageURL}`);

      getBase64ImageDimensions(imageURL)
        .then((dimensions) => {
          console.log(
            "Dimensões da imagem:",
            dimensions.width,
            "x",
            dimensions.height
          );
          return {
            width: dimensions.width,
            height: dimensions.height,
          };
          // Faça o que for necessário com as dimensões
        })
        .catch((error) => {
          console.error(error.message);
          //setAlerta("upscaleError");
        });

      // Obtém as dimensões da imagem
      const dimensions = await getBase64ImageDimensions(imageURL);

      const CanvasModels = ["CanvasAlchemy", "CanvasAlchemyPro"];

      if (dimensions.width && dimensions.height) {
        const { width, height } = dimensions;
        const key = `${width}x${height}`;
        console.log("PARAMETROS:", key);

        // Mapeamento das dimensões para funções correspondentes
        const promptFunctions = {
          "1024x1024": {
            default: geraPrompt1024x1024,
            canvas: geraPromptCanvas1024x1024,
          },
          "1536x1536": {
            default: geraPrompt1536x1536,
            canvas: geraPromptCanvas1536x1536,
          },
          "1024x1344": {
            default: geraPrompt1024x1344,
            canvas: geraPromptCanvas1024x1344,
          },
          "1344x1024": {
            default: geraPrompt1344x1024,
            canvas: geraPromptCanvas1344x1024,
          },
          "1024x1536": {
            default: geraPrompt1024x1536,
            canvas: geraPromptCanvas1024x1536,
          },
          "1536x1024": {
            default: geraPrompt1536x1024,
            canvas: geraPromptCanvas1536x1024,
          },
          "1224x816": {
            default: geraPrompt1224x816,
            canvas: geraPromptCanvas1224x816,
          },
          "816x1224": {
            default: geraPrompt816x1244,
            canvas: geraPromptCanvas816x1224,
          },
          "1224x1840": {
            default: geraPrompt1224x1840,
            canvas: geraPromptCanvas1224x1840,
          },
          "1840x1224": {
            default: geraPrompt1840x1224,
            canvas: geraPromptCanvas1840x1224,
          },
          "1752x1336": {
            default: geraPrompt1752x1336,
            canvas: geraPromptCanvas1752x1336,
          },
          "1336x1752": {
            default: geraPrompt1336x1752,
            canvas: geraPromptCanvas1336x1752,
          },
        };

        //prompt da imagem
        const userChoicesResponse = await axios.get(
          `${add_Back}/imagens/${selectedImage.id_imagem}`,
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const userChoices = userChoicesResponse.data[0].parametros;
        console.log("Prompt upscale do usuario galeria: ", userChoices);

        if (promptFunctions[key]) {
          const geraPrompt = CanvasModels
            ? promptFunctions[key].canvas
            : promptFunctions[key].default;
          const upscaleResult = geraPrompt(userChoices, user, imageURL);
          // setImageUpscale(upscaleResult);
          console.log("PROMPT DO UPSCALE", upscaleResult);
          //console.log(userChoices);

          //visibilidade privada ou publica
          const visibilidadeimageResponse = await axios.get(
            `${add_Back}/galeria-imagem/${selectedImage.id}`,
            {
              headers: {
                Authorization: `${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const visibilidade = visibilidadeimageResponse.data[0].privado;

          //nome do modelo
          const id_modeloimageResponse = await axios.get(
            `${add_Back}/imagens/${selectedImage.id_imagem}`,
            {
              headers: {
                Authorization: `${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const id_modelo = id_modeloimageResponse.data[0].id_modelo;

          let JsonBodyUpscale = {
            page: id_modelo,
            prompt: upscaleResult,
            image: imageURL,
            imageName: nomeImage,
            type: visibilidade,
          };
          try {
            const optionFetchGeraImagemComfy = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(JsonBodyUpscale),
            };
            console.log("oi log2", optionFetchGeraImagemComfy);
            const response = await fetchGeraImagemComfy(
              optionFetchGeraImagemComfy
            );

            if (response.erro) {
              //setImageUpscale(imagepreta);
              //setAlerta("upscaleError");
            } else {
              //setImageUpscale(response.msg);
              //setAlerta(null);
              //onMoedasUpdate(response.moedas);
            }
          } catch (error) {
            console.error("Erro ao realizar fetch:", error);
            //setAlerta("upscaleError");
            // Trate o erro conforme necessário
          }
        } else {
          console.error("Dimensões da imagem não suportadas.");
          //setAlerta("upscaleError");
        }
      } else {
        console.error("Não foi possível extrair as dimensões da imagem.");
        //setAlerta("upscaleError");
      }
    } catch (error) {
      console.error("Erro ao puxar a imagem:", error);
    }

    //console.log('ID da imagem: ', selectedImage);
  };

  //------------------------------------------------------------------

  //------------------------------------------------------------------
  //   const upscaleGallery = async (image) => {
  //     const token = Cookies.get("token");
  //     console.log('ID da imagem dentro do up: ', selectedImage.id);

  //     //${selectedImage.id}
  //     try {
  //       const imageResponse = await axios.get(`${add_Back}/galeria-imagem/${selectedImage.id}`, {
  //         headers: {
  //           Authorization: `${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       console.log("Imagem encontrada:", imageResponse.data[0].imagem)

  //     } catch (error) {
  //       console.error("Erro ao puxar a imagem:", error);
  //     }
  // };

  //------------------------------------------------------------------

  const token = Cookies.get("token");
  const decodedToken = jwtDecode(token);
  const clientId = decodedToken.clienteId;
  const preloadImages = async () => {
    try {
      const response = await axios.get(
        `${add_Back}/galeria/${clientId}?page=${page}&limit=${8}`,
        {
          headers: {
            Authorization: `${token}`, // Inclua o token no cabeçalho
            "Content-Type": "application/json",
          },
        }
      );
      const imagesWithoutQuotes = response.data.map((image) => ({
        ...image,
        imagem: image.imagem.replace(/^"|"$/g, ""),
      }));
      // Filtrar imagens para garantir que apenas imagens únicas sejam carregadas
      const uniqueImages = filterUniqueImages(imagesWithoutQuotes);
      setAllImages(uniqueImages);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao buscar imagens da galeria:", error);
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      preloadImages();
    }, 1000); // 1000 milissegundos = 1 segundo

    return () => clearTimeout(timeoutId);
  }, []);

  const [qtdFotos, setQtdFotos] = useState(1);

  const loadMoreImages = async () => {
    if (loadingMore) return; // Se já estiver carregando mais imagens, não faça nada
    setLoadingMore(true); // Sinaliza que estamos carregando mais imagens
    try {
      const response = await axios.get(
        `${add_Back}/galeria/${clientId}?page=${
          page + 1
        }&limit=${imagesPerPage}`,
        {
          headers: {
            Authorization: `${token}`, // Inclua o token no cabeçalho
            "Content-Type": "application/json",
          },
        }
      );
      if(response.data.length <= 0) { 
        setQtdFotos(0);
      }

      const imagesWithoutQuotes = response.data.map((image) => ({
        ...image,
        imagem: image.imagem.replace(/^"|"$/g, ""),
      }));
      // Filtrar imagens para garantir que apenas imagens únicas sejam carregadas
      const uniqueImages = filterUniqueImages(imagesWithoutQuotes);
      setAllImages((prevImages) => [...prevImages, ...uniqueImages]);
      setPage((prevPage) => prevPage + 1); // Atualiza a página corretamente em 1
    } catch (error) {
      console.error("Erro ao buscar mais imagens da galeria:", error);
    } finally {
      setLoadingMore(false); // Marca que a operação de carregar mais imagens foi concluída
    }
  };

  // Função para filtrar imagens únicas com base no ID
  const filterUniqueImages = (images) => {
    const uniqueIds = new Set();
    return images.filter((image) => {
      if (!uniqueIds.has(image.id)) {
        uniqueIds.add(image.id);
        return true;
      }
      console.log(`Imagem duplicada: ${image.id}`);
      return false;
    });
  };

  const [timerString, setTimerString] = useState("");
  const [timerLoadingMore, setTimerLoadingMore] = useState("");

  useEffect(() => {
    let timerId;

    if (!isLoading) {
      timerId = setTimeout(() => {
        setTimerString("hidden");
      }, 515); // Defina o tempo do timer em milissegundos (aqui é 5 segundos)
    } else {
      setTimerString("");
      clearTimeout(timerId);
    }

    if (!loadingMore) {
      timerId = setTimeout(() => {
        setTimerLoadingMore("hidden");
      }, 515); // Defina o tempo do timer em milissegundos (aqui é 5 segundos)
    } else {
      setTimerLoadingMore("");
      clearTimeout(timerId);
    }

    return () => clearTimeout(timerId);
  }, [isLoading]);

  return (
    <div>
      <Navbar
        title={i18n.t("PERSONAL GALLERY")}
        subtitle={i18n.t("Images created by you.")}
        description={i18n.t(
          "Here you can see all the images that you created."
        )}
      />
      <div
        className="flex justify-content-center pt-[118px] min-h-screen"
        style={{ paddingLeft: "50px", paddingRight: "50px" }}
      >
        <ContainerCard>
          <div
            className={`${styles["gallery-setting"]} flex flex-col lg:flex-row justify-end align-items-center`}
            style={{ marginLeft: "-58px" }}
          >
            {" "}
          </div>
          <div className="-mt-15">
            {isLoading ? (
              <div
                className={`font-title font-bold text-2xl
              animate-pulse transition-opacity duration-500 ease-in-out`}
              >
                {i18n.t("LOADING...")}
              </div>
            ) : (
              <>
                <MasonryLayout images={allImages} onImageClick={openModal} />
                {loadingMore ? (
                  <div
                    className={`flex font-title font-bold text-2xl 
                  animate-pulse justify-center items-center
                  transition-opacity duration-500 ease-in-out -mt-2 mb-4`}
                  >
                    {i18n.t("LOADING...")}
                  </div>
                ) : (
                  (qtdFotos > 0 ? 
                  <button
                    onClick={loadMoreImages}
                    className={`
                  black-border-2 font-title text-2xl my-2 p-4 bg-white
                  text-black transition-all ease-in-out duration-500
                  hover:bg-black hover:text-white font-bold -mt-2 mb-4`}
                  >
                    {i18n.t("Load more")}
                  </button>
                  :
                  <h1
                  
                  className="font-title text-2xl font-bold">{t("That's all")}</h1>
                  )
                )}
              </>
            )}
            <ReactModal
              className="absolute top-0 left-0 transition-opacity opacity-100 duration-500 w-[100vw] h-full flex flex-row justify-center items-center"
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Modal"
              ariaHideApp={false}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  zIndex: 1000,
                },
              }}
            >
              {selectedImage && (
                <div className="flex  animate-fade animate-duration-700 ease-in-out flex-row justify-center items-center h-[90%]">
                  <button
                  style={{
                    zIndex: 50
                  }}
                    className="absolute top-[3%] left-[85%] lg:top-[3%] lg:left-[95%] scale-125 place-items-start"
                    onClick={closeModal}
                  >
                    <FaXmark color="white" size={35} />
                  </button>
                  <img
                    src={`${selectedImage.imagem}`}
                    alt={selectedImage.nick_name}
                    className=" hidden lg:inline"
                    style={{
                      width: "auto",
                      height: "90%",
                      zIndex: 1000,
                    }}
                  />
                  <div className="relative inline lg:hidden">
                    <div className="max-w-[100%] max-h-[auto]">
                    <img
                      src={`${selectedImage.imagem}`}
                      alt={selectedImage}
                      style={{
                        width: "100%",
                        height: "fit-content",
                        objectFit: "contain"
                      }}
                      className="lg:hidden"
                    />
                    </div>
                    <div className="absolute flex bottom-[-13%] z-30 md:hidden lg:hidden w-full h-[10%]">
                      <FaDownload
                        size={32}
                        color="white"
                        href={`${selectedImage.imagem}`}
                        download={`${selectedImage.nick_name}.jpg`}
                        onMouseEnter={handleHovering}
                        onMouseLeave={handleHovering}
                        className="cursor-pointer ml-4"
                      ></FaDownload>
                      <FaHeart
                        size={32}
                        className="ml-4"
                        onClick={handleHeartClick}
                        color={isHeartRed ? "red" : "white"}
                        style={{
                          transition: "color 0.5s ease",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div className="absolute flex items-end top-[-14%] w-full h-[10%]">
                      <img
                        src={
                          `${selectedImage.imagem_perfil}` ||
                          `${perfil_default}`
                        }
                        className="w-[16vw] h-[16vw] ml-3 max-w-52 max-h-52"
                      />
                      <h2 className="font-paragraph text-[12vw] max-w-52 max-h-52 ml-3 text-white">
                        {selectedImage.nick_name}
                      </h2>
                    </div>
                  </div>
                  <div className="flex-col hidden lg:inline-flex items-center justify-center h-[90%] bg-white py-4 px-4 ">
                    <img
                      src={
                        `${selectedImage.imagem_perfil}` || `${perfil_default}`
                      }
                      className=" mb-5 hover:w-28 hover:h-28 rounded-[100%] ease-in-out group-transition duration-500 w-20 h-20"
                    ></img>
                    <h1 className="text-sm font-title font-bold">
                      {i18n.t("REQUESTED BY")}
                    </h1>
                    <h2 className="font-paragraph text-2xl -mt-2">
                      {selectedImage.nick_name}
                    </h2>
                    <FaDownload
                      size={28}
                      onClick={() => handleDownloadClick(selectedImage)}
                      download={`${selectedImage.nick_name}.jpg`}
                      onMouseEnter={handleHovering}
                      onMouseLeave={handleHovering}
                      className="cursor-pointer w-full mt-3"
                    ></FaDownload>
                    <p
                      className={`font-title mt-3 ${
                        isHovering
                          ? `animate-fade-up animate-duration-500`
                          : `animate-fade animate-alternate-reverse animate-duration-500`
                      }`}
                    >
                      {i18n.t("DOWNLOAD NOW")}!
                    </p>
                    {/* Botão Upscale Condicional */}
                    {/* {isUpscaleAllowed && (
                      <button
                        className={`sm:text-[3.8vw] md:text-[2vw] lg:text-[1.8vw] border-2 border-black border-solid p-2 mt-2 ease-in-out duration-300 transition-colors bg-[#80d5ff] bg-opacity-70 text-black hover:bg-[#80d5ff] hover:bg-opacity-100 hover:text-black hover:cursor-pointer font-title hover:bg-blue-200`}
                        onClick={upscaleGallery}
                      >
                        REFINE (5 RP)
                      </button>
                    )} */}
                  </div>
                </div>
              )}
            </ReactModal>
          </div>
        </ContainerCard>
      </div>
      <div className={`${isLoading ? `absolute bottom-0 w-full` : ``}`}>
        <Footer />
      </div>
    </div>
  );
};

export default PersonalGallery;
