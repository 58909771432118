import React, { useEffect, useState } from 'react';
import ArchitectureImg from '../../assets/architectureImg.webp';
import EngineeringImg from '../../assets/engineeringImg.webp';
import InteriorDesignImg from '../../assets/interiorDesignImg.webp';
import { FaArrowDown } from 'react-icons/fa';
import "./UncolouredImagesStyle.css";
import { Link as ScrollLink } from 'react-scroll';
import {useTranslation} from "react-i18next";
import { Trans } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import Cookies from "js-cookie";
import { Link, useNavigate } from 'react-router-dom';

export default function UncolouredImages() {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isBoxOpen, setIsBoxOpen] = useState([false, false, false]);

  const verificarToken = () => {
    const token = Cookies.get('token');
    if (!token) {
      return false;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp >= currentTime;
    } catch (error) {
      return false;
    }
  };

  const handleLinkClick = (path) => {
    if (!verificarToken()) {
      navigate('/Login');
    } else {
      navigate(path);
    }
  };

  const handleBoxClick = (id) => {
    setIsBoxOpen(prevState => prevState.map((value, index) => index === id));
  }

  const navigate = useNavigate();
  const {t} = useTranslation();
  const [winWidth, setWinWidth] = useState(0);
  const [winHeight, setWinHeight] = useState(0);

  useEffect(() => {
    setWinWidth(window.innerWidth / 3);
    setWinHeight(window.innerHeight);
    console.log("largura: " + winWidth + "altura: " + winHeight);
  }, []);

  return (
    <div className='relative'>
      
    {window.innerWidth < 1024 ? 
    (
      <div></div>
    )
    :
    (
      <section id="uncolored-images" className="h-screen w-full max-w-screen relative flex min-[1030px]:flex-row">
        <div
          className="flex w-1/3 h-auto flex-col relative cursor-pointer overflow-hidden"
          onClick={() =>handleLinkClick("/Dashboard/initial")}
          onMouseEnter={() => setIsHovered1(true)}
          onMouseLeave={() => setIsHovered1(false)}
          >
          <img
          style={{
            minWidth: winWidth,
            minHeight: winHeight,
            width: window.innerWidth / 3,
            height: window.innerHeight
          }}
            src={ArchitectureImg}
            className="filter grayscale hover:grayscale-0 transition ease-in-out duration-500 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
          />
          <h1 className="text-orange-500 font-title absolute top-0 left-0 text-3xl font-bold my-10 mx-4">
          {t("ARCHITECTURE")}:
          </h1>
          {isHovered1 && (
            <p className="text-white font-paragraph text-xl text-left font-bold animate-fade-down animate-once animate-duration-500 absolute top-0 left-0 my-[4.5rem] mx-4">
              <Trans 
            className="uppercase text-left"
            i18nKey={"AI TOOLS THAT WILL CHANGE YOUR LIFE"}>
              AI TOOLS THAT WILL <span className="text-black font-title font-bold text-2xl">
              CHANGE</span> YOUR <span className="text-black font-title font-bold text-2xl">LIFE</span>
            </Trans>
            </p>
          )}
          {!isHovered1 && (
            <p className="text-white font-paragraph text-xl font-bold 
            animate-fade-down animate-alternate-reverse 
            animate-once animate-duration-500 absolute top-0 
            left-0 my-[4.5rem] mx-4 text-left">
            <Trans 
            className="uppercase text-left"
            i18nKey={"AI TOOLS THAT WILL CHANGE YOUR LIFE"}>
              AI TOOLS THAT WILL <span className="text-black font-title font-bold text-2xl">
              CHANGE</span> YOUR <span className="text-black font-title font-bold text-2xl">LIFE</span>
            </Trans>
          </p>
          )}
  
          {isHovered1 && (
          <p className="absolute font-title bottom-0 right-0 text-white animate-fade animate-duration-500 font-bold m-3 text-3xl">{t("FAST")}</p>  
          )}
          {!isHovered1 && (
          <p className="absolute font-title bottom-0 right-0 text-white animate-fade animate-duration-500 animate-alternate-reverse font-bold m-3 text-3xl">{t("FAST")}</p>
          )}
        </div>  
        <div 
        onClick={() =>handleLinkClick("/Dashboard/initial")}
        onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
          className="flex flex-col cursor-pointer justify-between items-center relative w-1/3 overflow-hidden">
          <img
          style={{
            minWidth: winWidth,
            minHeight: winHeight,
            width: window.innerWidth / 3,
            height: window.innerHeight
          }}
            src={EngineeringImg}
            className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 filter grayscale hover:grayscale-0 transition ease-in-out duration-300"
          />
          <div>
            <h1 className="text-black  font-title  absolute top-0 left-0 text-3xl font-bold my-10 mx-4">
            {t("CIVIL ENGINEERING")}:
            </h1>
            {isHovered2 && (
            <p className="text-white font-paragraph text-xl font-bold animate-fade-down animate-once animate-duration-500 absolute top-0 left-0 my-[4.5rem] mx-4">
                {t("START TODAY FOR")} <span className="text-white font-title font-bold text-2xl">{t("FREE")}</span>
            </p>
            )}
            {!isHovered2 && (
              <p className="text-white font-paragraph text-xl font-bold animate-fade-down animate-alternate-reverse animate-once animate-duration-500 absolute top-0 left-0 my-[4.5rem] mx-4">
              {t("START TODAY FOR")} <span className="text-white font-title font-bold text-2xl">{t("FREE")}</span>
            </p>
          )}
          </div>
  
          {isHovered2 && (
          <p className="absolute font-title bottom-0 right-0 text-white animate-fade animate-duration-500 font-bold m-3 text-3xl">{t("FREE")}</p>  
          )}
          {!isHovered2 && (
          <p className="absolute bottom-0 font-title right-0 text-white animate-fade animate-duration-500 animate-alternate-reverse font-bold m-3 text-3xl">{t("FREE")}</p>
          )}
        </div>
        <div 
        onClick={() =>handleLinkClick("/Dashboard/initial")}
        onMouseEnter={() => setIsHovered3(true)}
          onMouseLeave={() => setIsHovered3(false)}
          className="relative cursor-pointer w-1/3 overflow-hidden">
          <img
            src={InteriorDesignImg}
            style={{
              minWidth: winWidth,
            minHeight: winHeight,
            width: window.innerWidth / 3,
            height: window.innerHeight
            }}
            className="filter grayscale hover:grayscale-0 transition ease-in-out duration-300 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
          />
          <h1 className="text-orange-500 font-title absolute top-0 left-0 text-3xl font-bold my-10 mx-4">
          {t("INTERIOR DESIGN")}:
          </h1>
          {isHovered3 && (
            <p className="text-black font-paragraph text-xl text-left font-bold animate-fade-down animate-once animate-duration-500 absolute top-0 left-0 my-[4.5rem] mx-4">
                <Trans i18nKey={"THE BEST NEURAL NETWORK MODELS"}>
                THE BEST 
                <span className="text-white font-title font-bold text-2xl">NEURAL NETWORK</span>MODELS
            </Trans>
            </p>
            )}
            {!isHovered3 && (
              <p className="text-black font-paragraph text-left text-xl font-bold animate-fade-down animate-alternate-reverse animate-once animate-duration-500 absolute top-0 left-0 my-[4.5rem] mx-4">
              <Trans i18nKey={"THE BEST NEURAL NETWORK MODELS"}>
                THE BEST 
                <span className="text-white font-title font-bold text-2xl">NEURAL NETWORK</span>MODELS
            </Trans>
            </p>
          )}
  
          {isHovered3 && (
          <p className="absolute bottom-0 font-title right-0 text-black animate-fade animate-duration-500 font-bold m-3 text-3xl">{t("ADVANCED")}</p>  
          )}
          {!isHovered3 && (
          <p className="absolute bottom-0 font-title right-0 text-black animate-fade animate-duration-500 animate-alternate-reverse font-bold m-3 text-3xl">{t("ADVANCED")}</p>
          )}
        </div>
      </section>
    )}
    <div className='absolute bottom-0 left-1/2 -translate-x-1/2 lg:inline hidden'>
        <ScrollLink to="greetings-screen"
          smooth={true}
          offset={0}
          duration={750}>
          <FaArrowDown
            size={55}
            color="white"
            className="opacity-70 z-50 cursor-pointer mb-3"
            onClick={handleBoxClick}
          />
        </ScrollLink>
        </div>
    </div>
  );
}