import React, { useRef, useState } from "react";
import { useEffect } from "react";
import "../index.css";
import ImageUploader from "../components/RenderComponents/ImageUploader";
import ImageCropper from "../components/RenderComponents/ImageCropper";
import NewPopUpComponent from "../components/Generic/NewPopUpComponent.jsx";
// import Floors from "../components/RenderComponents/Floors";
// import WhatExterior from "../components/RenderComponents/WhatExterior";
import GenericRenderComponent from "../components/RenderComponents/GenericRenderComponent.jsx";
import whatInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatInteriors.json";
import timeExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeExteriors.json";
import timeInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeInteriors.json";
import styleInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleInteriors.json";
import styleExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleExteriors.json";
import whatExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatExteriors.json"
import whereExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whereExteriors.json"
import weatherExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/weather.json"
// import Where from "../components/RenderComponents/WhereExterior";
// import Time from "../components/RenderComponents/Time";
// import Style from "../components/RenderComponents/Style";
import ColorPalette from "../components/RenderComponents/ColorPalette";
import Materials from "../components/RenderComponents/Materials";
import AIImmaginationRange from "../components/RenderComponents/AIImmaginationRange";
import RenderButton from "../components/RenderComponents/RenderButton";
import Navbar, { navbarHeight } from "../components/Navbar";
import Footer from "../components/Footer.jsx";
import AIModels from "../components/RenderComponents/AIModels";
import Seed from "../components/RenderComponents/Seed";
import Type from "../components/RenderComponents/Type";
//import { geraPrompt } from '../apiComfy/Brain.js';
import WallpaperGen from "../assets/wallpaperRenderPage.webp";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import Visibility from "../components/RenderComponents/Visibility";
import Precision from "../components/RenderComponents/Precision.jsx";
import CustomRange from "../components/RenderComponents/CustomRange.jsx";
// import Weather from "../components/RenderComponents/Weather.jsx";
// Importar apenas os arquivos "Pro"
import { geraPrompt as geraPromptLandscapePro } from "../apiComfy/ReDecorate/Redecorate_Pro_Landscape.js";
import { geraPrompt as geraPromptSquarePro } from "../apiComfy/ReDecorate/Redecorate_Pro_Square.js";
import { geraPrompt as geraPromptPortraitPro } from "../apiComfy/ReDecorate/Redecorate_Pro_Portrait.js";

import { geraPrompt as geraPromptLandscapeStandart } from "../apiComfy/ReDecorate/Redecorate_Standart_Landscape.js";
import { geraPrompt as geraPromptSquareStandart } from "../apiComfy/ReDecorate/Redecorate_Standart_Square.js";
import { geraPrompt as geraPromptPortraitStandart } from "../apiComfy/ReDecorate/Redecorate_Standart_Portrait.js";
import Accordion from "../components/RenderComponents/Accordion.jsx";
import InfoBox from "../components/InfoBox.jsx";
import { offensiveWords } from "../badwords.js";
import { handleTranslateClick } from "../components/Generic/translationUtils.js";
import { add_Back } from "../address.js";


export default function ReDecoratePage() {


    // Add useState and useEffect to detect desktop/mobile for positioning
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

    useEffect(() => {
      const handleResize = () => {
        setIsDesktop(window.innerWidth > 1024);  // Update the state based on the window width
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);



  const standardColors = {
    active: "bg-[#a1ff80] text-black",
    inactive: "bg-[#d4ffb3] text-black",
    hover: "bg-[#a1ff80] text-black",
    valor: 2,
  };

  const professionalColors = {
    active: "bg-[#fcac7f] text-black",
    inactive: "bg-[#fdd7bc] text-black",
    hover: "hover:bg-[#fcac7f] hover:text-black",
    valor: 3,
  };

  
  const [isPromptEnabled, setIsPromptEnabled] = useState(false);
  const [promptInput, setPromptInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [previousTranslatedText, setPreviousTranslatedText] = useState("");
  const [currentPrompt, setCurrentPrompt] = useState("");  // This will store the latest input before translation
  const [isTranslating, setIsTranslating] = useState(false); // Handle translation state


// Define the PromptToggleOrMessage function above the return block
const PromptToggleOrMessage = () => {
  const storedPlanId = localStorage.getItem("id_plan");

  if (storedPlanId === "1") {  // If the user is a free user (planId = 1)
    return (
      <div className="text-red-600 font-bold text-2xl mt-4">
        THIS TOOL IS FOR PREMIUM USERS ONLY,<a href="https://www.arcseedai.com/PlansPage/ENTHUSIAST"target="_blank"className="text-white hover:text-gray-300"> CLICK HERE </a> TO UNLOCK IT.
      </div>
    );
  } else {
    return (
      <input
        id="promptToggle"
        type="checkbox"
        className="w-8 h-8 border-4 border-black mt-5 rounded"
        checked={isPromptEnabled}
        onChange={(e) => handlePromptToggle(e)}  // The toggle behavior only for premium users
      />
    );
  }
};




  // Function to handle the input change
  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    let containsOffensiveWord = false;
    let foundTerm = null;
  
    // Extrair os nomes das opções dos dois arquivos JSON
    const exteriorOptions = whatExteriorsJSON.param.options.map((opt) => opt.name);
    const interiorOptions = whatInteriorsJSON.param.options.map((opt) => opt.name);
  
    // Combinar todas as opções em um único array
    const allOptions = [...exteriorOptions, ...interiorOptions];
  
    // Verifica se há palavras ofensivas e remove-as do input
    offensiveWords.forEach((word) => {
      const regex = new RegExp(`\\b${word}\\b`, "gi");
      if (regex.test(inputValue)) {
        containsOffensiveWord = true;
        inputValue = inputValue.replace(regex, "");  // Remove a palavra ofensiva
      }
    });
  
    // Verificar se o input contém alguma das opções dos JSONs
    allOptions.forEach((option) => {
      const regex = new RegExp(`\\b${option}\\b`, "gi");
      if (regex.test(inputValue)) {
        foundTerm = option;  // Salva o termo encontrado
      }
    });

  
    // Mostra mensagem de erro se houver palavras ofensivas
    if (containsOffensiveWord) {
      setErrorMessage("Your input contains offensive words and has been modified.");
    } else {
      setErrorMessage("");
    }
  
    // Atualiza os estados relacionados ao input
    setCurrentPrompt(inputValue);
    setPromptInput(inputValue);
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      prompt: inputValue,
      what: foundTerm
    }));
  };

// Function to handle translation when the button is clicked
const handleTranslateClick = async () => {
  // Disable the button and change the text to "Translating..."
  setIsTranslating(true);

  try {
    const response = await fetch(`${add_Back}/translate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: promptInput }),  // Send the current prompt text
    });
    
    const data = await response.json();
    
    if (response.ok) {
      // Update the prompt input with the translated text
      setPromptInput(data.translatedText);
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        prompt: data.translatedText,
      }));
    } else {
      console.error('Translation failed:', data.message);
    }
  } catch (error) {
    console.error('Error during translation:', error);
  } finally {
    // Re-enable the button and reset the text after 2 seconds
    setTimeout(() => {
      setIsTranslating(false);
    }, 2000); // 2-second delay
  }
};      



  // Add the new state variables for the prompt and toggle
  const handlePromptToggle = () => {
    const newIsPromptEnabled = !isPromptEnabled;
  
    setIsPromptEnabled(newIsPromptEnabled);
  
    if (newIsPromptEnabled) {
      setUserChoices({
        ...userChoices,
        aiModels: null, 
        type: "PROMPT",  // Set type to PROMPT
        what: null,
        time: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        whereExterior: null,
        weather: null,
        AIImmaginationRange: "3",
        RoomWeightRange: "2",
        RoomWeightStyleRange: "5",
        ShapeStrengthRange: "2",
        ShapeExposureRange: "3",
        ShapeBoostRange: "2",
        BoostExposureRange: "1",
      });
    } else {
      // Reset the user choices if the toggle is turned off
      setUserChoices({
        ...userChoices,
        type: "REGULAR",  
        aiModels: "default",
        what: null,
        time: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        whereExterior: null,
        weather: null,
        AIImmaginationRange: "3",
        RoomWeightRange: "2",
        RoomWeightStyleRange: "5",
        ShapeStrengthRange: "2",
        ShapeExposureRange: "3",
        ShapeBoostRange: "2",
        BoostExposureRange: "1",
      });
    }
  };





  // Função para verificar se o token está presente e válido
  const token = Cookies.get("token");
  function verificarToken() {

    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const handleAccordionToggle1 = (accordionId) => {
    setOpenAccordion1(openAccordion1 === accordionId ? null : accordionId);
  };

  const renderWallpaperImages = () => {
    const items = [];

    for (let i = 0; i <= Math.ceil(window.innerWidth / 1255) + 3; i++) {
      items.push(
        <img
          alt="Wallpaper not found"
          src={WallpaperGen}
          className="w-auto h-[500px] opacity-60"
        />
      );
    }

    return (
    window.innerWidth < 1024 ? 
      (
        <div className="absolute top-0 left-0 flex flex-col max-h-[700px] overflow-hidden">
          <div className="flex">{items}</div>
          <div className="flex">{items}</div>
        </div>
      )
      :
      ( 
        <div className="absolute top-0 left-0 flex flex-nowrap">{items}</div>
      )
    )
  };


  

  const [userChoices, setUserChoices] = useState({
    imageUploader: null,
    imageCropper: null,
    aiModels: null,
    type: "REGULAR",  
    seed: null,
    size: null,
    what: null,
    time: null,
    style: "Modern",
    materials: null,
    effects: null,
    visibility: "PUBLIC",
    precision: "STANDARD",
    AIImmaginationRange: "3",
    RoomWeightRange: "2",
    RoomWeightStyleRange: "5",
    ShapeStrengthRange: "2",
    ShapeExposureRange: "3",
    ShapeBoostRange: "2",
    BoostExposureRange: "1",
  });



  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [moedas, setMoedas] = useState(() => {
    const storedMoedas = localStorage.getItem("moedas_total");
    return storedMoedas ? parseInt(storedMoedas, 10) : 0;
  });

  const handleMoedasUpdate = (newMoedas) => {
    setMoedas(newMoedas);
    localStorage.setItem("moedas_total", newMoedas);
  };
  useEffect(() => {
    // Verifica o tamanho da tela e define o estado isSmallScreen
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Defina a largura limite para telas pequenas
    };

    // Executa a verificação no carregamento e quando o tamanho da tela muda
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Remove o event listener ao desmontar o componente
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const updateUserChoices = (category, choice) => {

    setUserChoices((prevChoices) => ({
      ...prevChoices,
      [category]: choice,
    }));
  };

  const [openAccordion, setOpenAccordion] = useState("");
  const [openAccordion1, setOpenAccordion1] = useState("");

  

  


  const decodedToken = jwtDecode(token);
  const user = decodedToken.clienteId; 


 // Move handleRender outside of convertToJson
  const handleRender = () => {

    let finalConfig;

    const nomeImage = `${user}_${Date.now()}.png`;
    sessionStorage.setItem("nomeImage", nomeImage);
  
    if (isPromptEnabled && promptInput) {
      // Fetch the appropriate `geraPrompt` structure based on precision and image cropper
      let geraPrompt;


  
      if (userChoices.precision === "PROFESSIONAL") {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptLandscapePro(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptPortraitPro(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptSquarePro(userChoices, user, nomeImage);
        }
      } else {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptLandscapeStandart(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptPortraitStandart(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptSquareStandart(userChoices, user, nomeImage);
        }
      }
  
      // Copy the geraPrompt structure into finalConfig
      finalConfig = { ...geraPrompt };
  
      // Replace only the "20" node with the custom prompt input
      if (finalConfig["20"] && finalConfig["20"]["inputs"]) {
        finalConfig["20"]["inputs"]["text"] = promptInput.trim();
      } else {
        console.error("Node 20 not found in the prompt structure.");
      }
    } else {
      // Otherwise, use the normal geraPrompt logic
      let geraPrompt;
  
      if (userChoices.precision === "PROFESSIONAL") {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptLandscapePro(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptPortraitPro(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptSquarePro(userChoices, user, nomeImage);
        }
      } else {
        if (userChoices.imageCropper === "landscape") {
          geraPrompt = geraPromptLandscapeStandart(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "portrait") {
          geraPrompt = geraPromptPortraitStandart(userChoices, user, nomeImage);
        } else if (userChoices.imageCropper === "square") {
          geraPrompt = geraPromptSquareStandart(userChoices, user, nomeImage);
        }
      }
  
      // Assign `geraPrompt` to `finalConfig`
      finalConfig = geraPrompt;
    }
  
    // Store the final configuration in sessionStorage (whether it's a normal prompt or a custom prompt)
    const saida = finalConfig;

    sessionStorage.setItem("GeraPrompt", JSON.stringify(saida));

  };

  const { t } = useTranslation();

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.init({
      ns: ["renderComponents", "Footers"]
    });

  }, [i18n]);

  
  
  

// Keep convertToJson as is
const convertToJson = () => {
  // Some logic...
};
useEffect(() => {
  /* Função responsável pelo título das páginas */
  document.title = "Re-Decorate - Arcseed AI";
  return () => {
    document.title = "Arcseed AI"; // Define o título padrão ao desmontar o componente
  };
}, []);

useEffect(() => {
  sessionStorage.removeItem("GeraPrompt");
  sessionStorage.removeItem("croppedImage");
  sessionStorage.removeItem("nomeImage");
  sessionStorage.removeItem("imageUploader");
  sessionStorage.removeItem("imageUploader2");
}, [])


 
  

  const [isPrompt, setIsPrompt] = useState(null);
  const [openCustomization, setOpenCustomization] = useState(false);
  const [openFinetuning, setopenFinetuning] = useState(false);

  useEffect(() => {
    setopenFinetuning(false);
    setOpenCustomization(false);

  }, [userChoices.type]);



  const handleTypeChoose = () => {

    /* verificação para resetar os parâmetros caso troque o type*/
    if (userChoices.type !== isPrompt) {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        what: null,
        whatInterior: null,
        whereExterior: null,
        time: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        visibility: "PUBLIC",
        precision: "STANDARD",
        AIImmaginationRange: "3",
      }));
      setIsPrompt(userChoices.type);

    } /*vem até aqui, se estiver dando problema na saída do json, tem que tirar*/

    if (userChoices.type === "PROMPT") {  // Check if Prompt Toggle is enabled
      return (
        <div>
    
          {/* Accordions for Prompt Toggle, similar to INTERIORS */}
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? (
              <div className="flex w-full h-[700px] bg-[#f3f4f6] flex-col max-w-screen justify-center relative items-center">
                {renderWallpaperImages()}
                <div className="mb-2">
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={timeInteriorsJSON}  // Adjust based on Prompt logic
                    isOpen={openAccordion === "time"}
                    onToggle={() => handleAccordionToggle("time")}
                  />
                </div>
                <div>
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={styleInteriorsJSON}  // Adjust based on Prompt logic
                    isOpen={openAccordion === "style"}
                    onToggle={() => handleAccordionToggle("style")}
                  />
                </div>
              </div>
            ) : (
              <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
                {renderWallpaperImages()}
                <div className="w-1/2">
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={timeInteriorsJSON}
                    isOpen={openAccordion === "time"}
                    onToggle={() => handleAccordionToggle("time")}
                  />
                </div>
                <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
                <div className="w-1/2">
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={styleInteriorsJSON}
                    isOpen={openAccordion1 === "style"}
                    onToggle={() => handleAccordionToggle1("style")}
                  />
                </div>
              </div>
            )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <Materials updateUserChoices={updateUserChoices} />
          </Accordion>
    
          {/* Fine-tuning section */}
          <div className="flex flex-col justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
              font-title cursor-pointer transition-all ease-in-out 
              duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
              border-solid border-black leading-[99%]`}
            >
              {t("Fine-tuning")}
            </h1>
          </div>
    
          <Accordion isOpen={openFinetuning}>
            <AIImmaginationRange 
              initialValue={userChoices.AIImmaginationRange}
              updateUserChoices={updateUserChoices}
            />
          </Accordion>
    
          <Visibility updateUserChoices={updateUserChoices} />
          <Precision
            updateUserChoices={updateUserChoices}
            standardColors={standardColors}
            professionalColors={professionalColors}
          />
    
          {/* Render Button */}
          <RenderButton
            onRenderButtonClick={handleRender}  // Reference the function here
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
            isPromptEnabled={isPromptEnabled}
          />
        </div>
      );
    } else if (userChoices.type === "REGULAR") {
      return (
        <div>
          {/* <What updateUserChoices={updateUserChoices} /> */}
      <GenericRenderComponent
      updateUserChoices={updateUserChoices}
      renderParamJSONConfig={whatInteriorsJSON}
      isOpen={true}
      />
      <div className="flex w-full justify-center items-center">
        <h1
          onClick={() => setOpenCustomization(!openCustomization)}
          className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
        >
          {t("CUSTOMIZATION")}
        </h1>
      </div>
      <Accordion isOpen={openCustomization}>
        {window.innerWidth < 1024 ? 
        (
        <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
          {renderWallpaperImages()}
            {/* <SizeDecorate updateUserChoices={updateUserChoices} /> */}
            <div className="mb-2">
            <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={timeInteriorsJSON}
            isOpen={openAccordion === "time"}
            onToggle={() => handleAccordionToggle("time")}
            />
            {/* <Time
              isOpen={openAccordion1 === "time"}
              onToggle={() => handleAccordionToggle1("time")}
              updateUserChoices={updateUserChoices}
            /> */}
          </div>
            <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={styleInteriorsJSON}
            isOpen={openAccordion === "style"}
            onToggle={() => handleAccordionToggle("style")}
            />
            {/* <Style
              isOpen={openAccordion === "style"}
              onToggle={() => handleAccordionToggle("style")}
              updateUserChoices={updateUserChoices}
            /> */}
        </div>
        )
        :
        (
        <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
          {renderWallpaperImages()}
          <div className="flex flex-col h-[100%] justify-center w-[50vw]">
            {/* <SizeDecorate updateUserChoices={updateUserChoices} /> */}
            <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={timeInteriorsJSON}
            onToggle={() => handleAccordionToggle("time")}
            isOpen={(openAccordion === "time")}
            />
            {/* <Time
              isOpen={openAccordion1 === "time"}
              onToggle={() => handleAccordionToggle1("time")}
              updateUserChoices={updateUserChoices}
            /> */}
          </div>
          <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
          <div className="flex flex-col h-[100%] justify-center w-[50vw]">
            <GenericRenderComponent
            updateUserChoices={updateUserChoices}
            renderParamJSONConfig={styleInteriorsJSON}
            onToggle={() => handleAccordionToggle1("style")}
            isOpen={(openAccordion1 === "style")}
            />
            {/* <Style
              isOpen={openAccordion === "style"}
              onToggle={() => handleAccordionToggle("style")}
              updateUserChoices={updateUserChoices}
            /> */}
          </div>
        </div>
        )}
        <ColorPalette updateUserChoices={updateUserChoices}/>
        <Materials updateUserChoices={updateUserChoices} />
      </Accordion>
      <div className="flex w-full justify-center items-center">
        <h1
          onClick={() => setopenFinetuning(!openFinetuning)}
          className={`
      font-title cursor-pointer transition-all ease-in-out 
      duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
      border-solid border-black leading-[99%]`}
        >
          {t("Fine-tuning")}
        </h1>
      </div>
      <Accordion isOpen={openFinetuning}>
        <div className="w-full lg:px-[10vw] flex flex-col lg:flex-row justify-center">
          <div className="lg:w-[50%] lg:flex-col lg:flex lg:justify-center">
            <div className="mt-4">
              <AIImmaginationRange
                initialValue={"3"}
                updateUserChoices={updateUserChoices}
              />
            </div>
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"ROOM WEIGHT"}
              startsAt={"2"}
              maxRange={"5"}
              infoBoxDescription={
                "Room weight will try to hold heavier to the original shape to decorate, but it can also decrease the amount of objects decorating the scene."
              }
              description={
                "How much the Shape of the room will influence on the AI calculation"
              }
              variableName={"RoomWeightRange"}
              options={["NULL", "HEAVY"]}
              className="mt-4"
            />
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"SHAPE EXPOSURE"}
              startsAt={"3"}
              maxRange={"5"}
              infoBoxDescription={
                "Stronger the exposure more the AI will let the shape participate on the final render."
              }
              description={
                "How long the AI should hold to the original image's form"
              }
              variableName={"ShapeExposureRange"}
              options={["SOFT", "MODERATE", "STRONG"]}
              className={"mt-4"}
            />
          </div>
          <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center">
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"ROOM WEIGHT EXPOSURE"}
              startsAt={"5"}
              maxRange={"5"}
              infoBoxDescription={
                "This slider will make more of the style of the room influence the render, it can be helpful to increase this value if the final image are not following the shape of the room properly."
              }
              description={
                "How much the shape of the room will stylize the final image"
              }
              variableName={"RoomWeightStyleRange"}
              className={"mt-4"}
              options={["WEAK", "STRONG"]}
            />
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"SHAPE STRENGTH"}
              startsAt={"2"}
              maxRange={"5"}
              infoBoxDescription={
                "How much the AI will try to keep the shape over trying to be creative."
              }
              description={
                "More creative the AI is less precise it tends to be"
              }
              variableName={"ShapeStrengthRange"}
              options={["Creative", "Precise"]}
              className="mt-4"
            />
            <CustomRange
              updateUserChoices={updateUserChoices}
              title={"SHAPE BOOST"}
              startsAt={"2"}
              maxRange={"5"}
              infoBoxDescription={
                "This is an extra boost on the shape of the room in case its no following properly"
              }
              description={"Give a boost on the room shape"}
              variableName={"ShapeStrengthRange"}
              options={["1", "2", "3", "4", "5"]}
              className="mt-4"
            />
          </div>
        </div>
        <CustomRange
          updateUserChoices={updateUserChoices}
          title={"BOOST EXPOSURE"}
          startsAt={"1"}
          maxRange={"5"}
          infoBoxDescription={
            "This value will determine how much the boost will linger on the rendering, sometimes its better to have lower boost but let it linger longer."
          }
          description={"How long the boost will stay active"}
          variableName={"BoostExposureRange"}
          className={"my-4"}
          options={["1", "2", "3", "4", "5"]}
        />
      </Accordion>
      <Visibility updateUserChoices={updateUserChoices} />
      <Precision
        updateUserChoices={updateUserChoices}
        standardColors={standardColors}
        professionalColors={professionalColors}
      />
      <RenderButton
            onRenderButtonClick={handleRender}  // Reference the function here
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
            isPromptEnabled={isPromptEnabled}
          />
        </div>
      );
    }
  };



  return (
    <div className="overflow-hidden">
      <NewPopUpComponent/>
      <Navbar
        title={t("Re-Decorate ")}
        userChoices={userChoices}
        description="Decorate an empty room in a few seconds with this tool! if you are an advanced user click here for the advanced toolset or turn the advanced toolset on your settings page here ,there all the generative pages will be in one place for fast editing and access."
        showPoints={true}
        moedas={moedas}
      />
      <div
      style={{
        marginTop: navbarHeight + 25
      }}
      >
        <ImageUploader
          title={"Drop the environment to be furnished here"}
          subtitle={
            "You can take a screenshot (PrtScr no teclado) and paste it here with Ctrl+v, to crop it below."
          }
          updateUserChoices={updateUserChoices}
        />
      </div>
      <ImageCropper
        image={sessionStorage.getItem("imageUploader")}
        updateUserChoices={updateUserChoices}
      />

     {/* Encapsulate Prompt in a light gray box with fixed width and animation */}
  <div className={`p-10  mt-10 max-w-4xl mx-auto transition-all duration-500 ${isPromptEnabled ? 'bg-gray-100' : 'bg-black'}`} > 
  {/* Prompt Field */}
  <div className="flex flex-col justify-center items-center">
    <label 
      htmlFor="promptInput" 
      className={`font-bold font-title flex items-center text-3xl transition-all duration-500 ${isPromptEnabled ? 'text-black' : 'text-white'}`}
    >
      PROMPT
      <div className="w-[1px] h-[1px] relative">
        <InfoBox
          customClass="absolute top-1/2 -translate-y-1/2 left-2"
          text={t("Prompts give you more control over the AI, They should be in English and have a 120 characters limit.")} 
        />
      </div>
    </label>

    <input
      id="promptInput"
      type="text"
      maxLength={120}
      value={promptInput}
      onChange={handleInputChange}
      disabled={!isPromptEnabled}  // Disable when the toggle is off
      className={`border-b-4 transition-all duration-500 ${isPromptEnabled ? 'border-black text-black bg-transparent' : 'border-white text-white bg-black'} mt-5 p-2 focus:outline-none w-full`}
      placeholder={t("Enter your Prompt here...")}
    />

    {/* Add a Translate Button */}
    <button
  onClick={handleTranslateClick}
  className={`bg-gray-500 hover:bg-gray-700 text-white font-bold mt-9 py-2 px-4 ${!isPromptEnabled || promptInput === previousTranslatedText || isTranslating ? 'opacity-50 cursor-not-allowed' : ''}`}
  disabled={!isPromptEnabled || promptInput === previousTranslatedText || isTranslating}  // Disable based on conditions
  aria-label={t("Translate prompt to English")}
>
{isTranslating ? t("TRANSLATING...") : t("TRANSLATE TO ENGLISH")}
</button>


    {/* Error message */}
    {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
  </div>

  {/* Centered Toggle with Bigger Size */}
  <div className="mt-10 flex flex-col justify-center items-center">
    <label 
      htmlFor="promptToggle" 
      className={`block text-md mb-5 font-bold transition-all duration-500 ${isPromptEnabled ? 'text-black' : 'text-white'}`}
    >
      {t("CLICK TO TURN THE PROMPT TOOL ON!")}<br /> {t("The Prompt has to be in english, click TRANSLATE TO ENGLISH if you need a translation.")}
    </label>

    {/* Use the PromptToggleOrMessage function here */}
    <PromptToggleOrMessage 
      isPromptEnabled={isPromptEnabled} 
      handlePromptToggle={handlePromptToggle} 
      t={t} 
   />
  </div>
</div>



<div className={`mt-[${navbarHeight}px]`}></div>
<div className="flex lg:flex-row flex-col w-full py-8 items-center justify-center max-w-screen">
  {/* AIModels with fade effect */}
  <div 
    className="w-1/2"
    style={{
      opacity: isPromptEnabled ? 0 : 1,                // Adjust opacity based on isPromptEnabled
      transition: 'opacity 0.5s ease-in-out',  // 0.5s smooth transition for fade
      visibility: isPromptEnabled ? 'hidden' : 'visible', // Ensure proper display when opacity reaches 0
    }}
  >
    <AIModels verticalSet={isSmallScreen} updateUserChoices={updateUserChoices} />
  </div>

  {/* Vertical Line */}
  <div
    className={`w-[0.06rem] h-[19rem] bg-black hidden lg:inline transition-opacity duration-500 ${isPromptEnabled ? 'opacity-0' : 'opacity-100'}`}
  ></div>

  {/* Seed Element */}
  <div
    className="w-1/2 flex justify-center items-center"
    style={{
      transform: isPromptEnabled 
        ? isDesktop 
          ? 'translateX(-50%) translateY(0)'   // Desktop: Horizontal slide, no vertical movement
          : 'translateX(0) translateY(-180px)'  // Mobile: Vertical slide to move upward
        : 'translateX(0) translateY(0)',       // Reset position when isPromptEnabled is false
      transition: 'transform 0.5s ease-in-out',  // Smooth transition for both axes
    }}
  >
    <Seed updateUserChoices={updateUserChoices} />
  </div>
</div>



      {handleTypeChoose()}
      <Footer footerVersion="light-footer" renderTitle="BRAINSTORM GEN" />
    </div>
  );
}